<template>
  <div>
    <Steps :model="steps" :readonly="true"/>
    <router-view v-slot="{ Component }" @prevPage="prevPage($event)" @nextPage="nextPage($event)" @complete="complete">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
import useMessage from "@/composable/useMessage";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";

const router = useRouter();
const store = useStore();
const message = useMessage();
const {t} = useI18n();


let steps = ref([
  {
    label: t('label.contratto'),
    to: "/contratto/wizard/contratto",
  },
  {
    label: t('label.contratto.wizard.soggetti'),
    to: "/contratto/wizard/soggetto",
  },
  {
    label: t('label.document', 2),
    to: "/contratto/wizard/upload",
  },
]);


const createContratto = async () => {
  const contratto = Object.assign({}, store.getters["contrattoWizard/contratto"]);
  const buyer = store.getters['contrattoWizard/buyer'];
  contratto.buyerId = buyer.id;
  const seller = store.getters['contrattoWizard/seller'];
  contratto.sellerId = seller.id;
  const contrattoInserito = await store.dispatch("contratto/insert", contratto);
  await store.dispatch("contrattoWizard/setContratto", contrattoInserito);
  message.successWithKey('message.contratto-insert-success');

  // force reload uploaded files for next step
  await store.dispatch("contratto/loadAllUploadedFiles", contratto);
};

const nextPage = async (event) => {
  if (1 === event.pageIndex) {
    await createContratto();
  }
  await router.push(steps.value[event.pageIndex + 1].to);
};

const prevPage = async (event) => {
  await router.push(steps.value[event.pageIndex - 1].to);
};

const complete = async () => {
  await store.dispatch("contrattoWizard/reset");
  await router.push("/contratti");
};

</script>

<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}
</style>
